<template>
  <div class="trendChart flex-column">
    <header>
      <div class="g-header" style="background: rgb(14, 21, 48)">
        <div class="left" @click="changego">
          <van-icon name="arrow-left" color="#333" size="20px" />
        </div>
        <!-- <div v-for="(item, index) in listx" :key="index">
          <spa>{{ item.title }}</spa>
        </div> -->
        <div
          class="middle fs-18"
          :class="{ active: showTitle }"
          @click="showTitle = showTitle"
        >
          <span>VIP{{ numx + status }}</span>
          <!-- <img src="@/assets/image/sanjiao.png" class="icon" alt="" /> -->
        </div>
        <div class="right" @click="showTime = !showTime">
          <span>{{time}}</span>
        </div>
      </div>
      <div class="blockHeight"></div>
    </header>
    <div class="g-content">
      <!-- 表格 -->
      <div class="table-wrap Pc28 h100">
        <div class="tr tb-head" style="color:#333">
          <div class="td issue">
            <span>{{$t('lunshu')}}</span>
            <span class="c_a0a0a0">{{ $t("yilou") }}</span>
          </div>
          <div class="td hezhi">
            <span>{{$t('hezhi')}}</span>
            <!-- <span class="c_a0a0a0">1</span> -->
          </div>
          <div class="td">
            <span>{{$t('dax')}}</span>
            <!-- <span class="c_a0a0a0">6</span> -->
          </div>
          <div class="td">
            <span>{{$t('xiaox')}}</span>
            <!-- <span class="c_a0a0a0">9</span> -->
          </div>
          <div class="td">
            <span>{{$t('dan')}}</span>
            <!-- <span class="c_a0a0a0">7</span> -->
          </div>
          <div class="td">
            <span>{{$t('shuang')}}</span>
            <!-- <span class="c_a0a0a0">8</span> -->
          </div>
          <div class="td">
            <span>{{$t('dadan')}}</span>
            <!-- <span class="c_a0a0a0">2</span> -->
          </div>
          <div class="td">
            <span>{{$t('xiaodan')}}</span>
            <!-- <span class="c_a0a0a0">3</span> -->
          </div>
          <div class="td">
            <span>{{$t('dashuang')}}</span>
            <!-- <span class="c_a0a0a0">4</span> -->
          </div>
          <div class="td">
            <span>{{$t('xiaoshuang')}}</span>
            <!-- <span class="c_a0a0a0">5</span> -->
          </div>
        </div>
        <div class="mescroll tb-body">
          <div>
            <div
              class="tr" v-for="(item, index) in issueList" :key="index" @click="findList">
              <div class="td issue c_fe5050">{{ item.issue_code }}</div>
              <div class="td hezhi">
                <span class="ball fs-12 ball-green">{{ item.code }}</span>
              </div>
              <!--               
              <div class="td" :class="item.base[0] == '做多' || item.base[1] == '做多' ? 'select' : '' " >
                {{ item.base[0] == "做多" || item.base[1] == "做多" ? $t('做多') : "" }}
              </div>
              <div
                class="td"
                :class="
                  item.base[0] == '做空' || item.base[1] == '做空'
                    ? 'select'
                    : ''
                "
              >
                {{
                  item.base[0] == "做空" || item.base[1] == "做空" ? $t('做空') : ""
                }}
              </div>
              <div
                class="td"
                :class="
                  item.base[0] == '平单' || item.base[1] == '平单'||item.base[0] == '平單' || item.base[1] == '平單'
                    ? 'select'
                    : ''
                "
              >
                {{
                  item.base[0] == "平单" || item.base[1] == "平单"||item.base[0] == '平單' || item.base[1] == '平單' ?$t('平单') : ""
                }}
              </div>
              <div
                class="td"
                :class="
                  item.base[0] == '平双' || item.base[1] == '平双'||item.base[0] == '平雙' || item.base[1] == '平雙'  
                    ? 'select'
                    : ''
                "
              >
                {{
                  item.base[0] == "平双" || item.base[1] == "平双"||item.base[0] == '平雙' || item.base[1] == '平雙'   ? $t('平双') : ""
                }}
              </div>
              
              <div
                class="td"
                :class="
                  item.base[0] == '多单' || item.base[1] == '多单'|| item.join == '多单' || item.join == '多單' ? 'select' : '' " >
                {{
                  item.base[0] == "多单" || item.base[1] == "多单"||item.join == "多单" || item.join == "多單" ? $t('多单') : ""
                }}
              </div>
              <div
                class="td"
                :class="
                  item.base[0] == '空单' || item.base[1] == '空单'|| item.join == '空单' || item.join == '空單'
                    ? 'select'
                    : ''
                "
              >
                {{
                  item.base[0] == "空单" || item.base[1] == "空单"||   item.join == "空单" || item.join == "空單" ?$t('空单') : ""
                }}
              </div>
              <div
                class="td"
                :class="
                  item.base[0] == '多双' || item.base[1] == '多双'||item.join == '多双' || item.join == '多雙'
                    ? 'select'
                    : ''
                "
              >
                {{
                  item.base[0] == "多双" || item.base[1] == "多双"||item.join == '多双' || item.join == '多雙' ? $t('多双') : ""
                }}
              </div>
              <div
                class="td"
                :class="
                  item.base[0] == '空双' || item.base[1] == '空双'||item.join == '空双' || item.join == '空雙'
                    ? 'select'
                    : ''
                "
              >
                {{
                  item.base[0] == "空双" || item.base[1] == "空双"||item.join == '空双' || item.join == '空雙' ?$t('空双') : ""
                }}
              </div> -->
              <!-- <div class="td select">
                {{ findList(item.base) ? "多单" : "" }}
              </div> -->
            
              <div class="td select"><p v-if="item.code >= 14 ? true : false">{{$t('dax')}}</p></div>
              <div class="td select"><p v-if="item.code <= 13 ? true : false" style="background:#0c9afe">{{$t('xiaox')}}</p></div>
              <div class="td select"><p v-if="item.code%2 === 1 ? true : false" style="background:#0c9afe">{{$t('dan')}}</p></div>
              <div class="td select"><p v-if="item.code%2 === 0 ? true : false">{{$t('shuang')}}</p></div>
              <div class="td select1"><p v-if="item.code >= 14 && item.code%2 === 1 ? true : false ">{{ $t('dadan') }}</p></div>
              <div class="td select1"><p v-if="item.code <= 13 && item.code%2 === 1 ? true : false " style="background:#0c9afe">{{ $t('xiaodan') }}</p></div>
              <div class="td select1"><p v-if="item.code >= 14 && item.code%2 === 0 ? true : false ">{{ $t('dashuang') }}</p></div>
              <div class="td select1"><p v-if="item.code <= 13 && item.code%2 === 0 ? true : false " style="background:#0c9afe">{{ $t('xiaoshuang') }}</p></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 遮罩层 -->
    <van-overlay v-model:show="showTitle" @click="showTitle = false">
      <div class="content">
        <ul class="lottery-list clear fs-12">
          <li
            class="lottery-item"
            :class="game_id == 1 ? 'activeli' : ''"
            @click="game_id = 1"
          >
            <div class="text">SINGAPORE</div>
          </li>
          <li
            class="lottery-item"
            :class="game_id == 2 ? 'activeli' : ''"
            @click="game_id = 2"
          >
            <div class="text">NEW ZEALAND</div>
          </li>
          <li
            class="lottery-item"
            :class="game_id == 3 ? 'activeli' : ''"
            @click="game_id = 3"
          >
            <div class="text">KUALA LUMPUR</div>
          </li>
          <li
            class="lottery-item"
            :class="game_id == 4 ? 'activeli' : ''"
            @click="game_id = 4"
          >
            <div class="text">AUSTRALIA</div>
          </li>
        </ul>
      </div>
    </van-overlay>
    <!-- 时间选择 -->
    <van-popup
      v-model:show="showTime"
      @click="showTime = false"
      :style="{ width: '100%' }"
      position="bottom"
    >
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        :title="showGourp"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="changeTime"
      />
    </van-popup>
    
  </div>
</template>
<script>
import userApi from "@/api/user";
import time from "@/utils/time";
import homeApi from "@/api/home";
export default {
  name: "TrendChart",
  components: {},
  data() {
    return {
      name: "",
      showTitle: false,
      showGourp: 0,
      showTime: false,
      minDate: new Date(2018, 0, 1),
      maxDate: new Date(),
      currentDate: new Date(),
      game_id: "",
      issueList: "",
      list: ["多单", "空单", "多双", "空双", "做多", "做空", "平单", "平双"],
      indexOne: "",
      time:'',
      listx: [],
      status: '',
      numx: '',
      dashow: false
    };
  },
  watch: {
    game_id(newVal) {
      if (newVal == 1) {
        this.name = "SINGAPORE";
      } else if (newVal == 2) {
        this.name = "NEW ZEALAND";
      } else if (newVal == 3) {
        this.name = "KUALA LUMPUR";
      } else if (newVal == 4) {
        this.name = "AUSTRALIA";
      }
      this.showIssueChange();
    },
  },
  methods: {
    timeStr(items) {
      return time.formatDatethree(items * 1000);
    },
    BtnOk() {
      this.show = false;
      this.$toast.loading({
        message: this.$t('jiazaizhong'),
        duration: 0,
        forbidClick: true,
      });
        userApi
          .orderList(
            this.begin_date ? this.begin_date : this.formatDate(new Date()),
            this.end_date ? this.end_date : this.formatDate(new Date())
          )
          .then((data) => {
            if (data.data.length) {
              this.listx = data.data;
            } else {
               this.listx =[]
              this.$toast(this.$t('Tips.zanwu'));
            }
          });
      this.loading = false;
      this.finished = true;
      this.$toast.clear();
    },
    formatDate(date) {
      let yy = date.getFullYear();
      let mm = date.getMonth() + 1;
      mm = mm.toString();
      let dd = date.getDate().toString();
      return yy + "-" + mm.padStart(2, 0) + "-" + dd.padStart(2, 0);
    },
    changeTime(value) {
      console.log(this.formatDate(value));
      if (this.showTimeType == 0) {
        this.begin_date = this.formatDate(value);
      } else {
        this.end_date = this.formatDate(value);
      }
    },
    findList(base) {
      for (let i = 0; i < this.list.length; i++) {
        if (base["0"] == this.list[i]) {
          console.log(i, this.list[i], "listIndex");
          return i;
        } else if (base["1"] == this.list[i]) {
          return i;
        }
      }
    },
    changego() {
      this.$router.go(-1);
    },
    showIssueChange() {
      this.$toast.loading({
        message: this.$t('jiazaizhong'),
        forbidClick: true,
        duration: 0,
      });
      homeApi
        .issueList(this.game_id)
        .then((data) => {
          console.log(data.data);
          this.issueList = data.data;
          this.$toast.clear();
        })
        .catch((err) => {
          this.$toast.clear();
          this.$toast.fail(err.msg);
        });
    },
  },
  mounted() {
  },
  created() {
            let  myDate = new Date();
       let time=  myDate.toLocaleDateString();
         this.time=time
    this.game_id = this.$route.query.game_id;
    this.status = this.$route.query.status;
    if(this.game_id == 2) {
      this.numx = '3'
    }else if(this.game_id == 3) {
      this.numx = '2'
    }else{
      this.numx = '1'
    }

    if (this.game_id == 1) {
      this.name = "SINGAPORE"
    } else if (this.game_id == 2) {
      this.name = "NEW ZEALAND"
    } else if (this.game_id == 3) {
      this.name = "KUALA LUMPUR"
    } else if (this.game_id == 4) {
      this.name = "AUSTRALIA";
    }
    this.showIssueChange();
    this.BtnOk();
  },
};
</script>
<style lang="less" scoped>
.trendChart {
  height: 100%;
}
.icon {
  width: 0.33rem;
  height: 0.25rem;
  transform: rotate(0deg);
  -webkit-transform-origin: 50% 35%;
  transform-origin: 50% 35%;
  transition: transform 0.3s;
}
.active {
  span {
    color: #ff7d20;
  }
  .icon {
    transform: rotate(180deg);
    transition: transform 0.3s;
  }
}
.content {
  z-index: 12;
  position: absolute;
  width: 100%;
  background: #1a243f;
  height: 1.18rem;
  top: 1.18rem;
}
.lottery-list {
  background-color: #1a243f;
  padding: 0.1rem;
}
.lottery-item {
  width: 25%;
  float: left;
  text-align: center;
  padding: 0.12rem;
  .text {
    padding: 0.1rem 0;
    border: 1px dashed #ccc;
    border-radius: 3px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.activeli .text {
  color: red;
  border-color: red;
}
.g-content {
  flex: 1;
  overflow: auto;
  background: #fff;
}
.table-wrap {
  width: 9rem;
  min-width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.table-wrap.Pc28 {
  width: 13.7rem;
}
.tr {
  display: flex;
}
.td {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  height: 1rem;
  word-break: break-word;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 0.35rem;
  border-right: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
}
.tb-head .td {
  height: 1.33rem;
}
.issue {
  width: 2.7rem;
  flex: auto;
}
.hezhi {
  width: 1.33rem;
  flex: unset;
}
.mescroll {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.select {
  color: #fff;
  background:#fff !important;
  p{
    background:#d09a5e;
    padding: 4px 6px;
    border-radius: 50%;
  }
}
.select1{
  color: #fff;
  font-size:13px;
  background:#fff !important;
  p{
    background:#d09a5e;
    padding: 7px 3px;
    border-radius: 50%;
  }
}
.Pc28 .tr .select:nth-child(4n + 3) {
  background-color: #ff4349;
}
.Pc28 .tr .select:nth-child(4n + 4) {
  background-color: #24abe2;
}
.Pc28 .tr .select:nth-child(4n + 5) {
  background-color: #8a0a61;
}
.Pc28 .tr .select:nth-child(4n + 6) {
  background-color: #fccf2e;
}
.ball-green {
  background: linear-gradient(to bottom, #41ff9a, #01ac7a);
}
.ball {
  display: inline-block;
  border-radius: 50%;
  margin: 0.04rem;
  width: 0.6rem;
  height: 0.6rem;
  text-align: center;
  line-height: 0.6rem;
  color: #fff;
  background-position: center center;
  background-size: cover;
  font-size: 0.38rem;
  overflow: hidden;
  position: relative;
  z-index: 1;
}
</style>