<template>
  <div class="register">
    <header style="margin-top: 1rem;">
      <h1 style="color: #1c1c36; line-height: 44px; font-size: 17px">注册</h1>
      <img src="@/assets/image/amblr.png" class="app-logo" alt="" />
    </header>
    <div class="g-content flex-column">
      <div class="register-main">
        <span style="text-align: left;">{{ $t('username') }}</span>
        <van-cell-group class="login-body van-cell-group">
          <van-field
            v-if="step==0"
            :placeholder="$t('plusername')"
            v-model="username"
            clearable
          >
            <template #left-icon>
              <img
                src="@/assets/image/user.png"
                class="left-form-icon"
                alt=""
              />
            </template>
          </van-field>
        </van-cell-group>
        <span style="text-align: left;">{{ $t('userpwd') }} </span>
        <van-cell-group class="login-body van-cell-group">
          <van-field
          v-if="step==0"
            clearable
            :placeholder="$t('plpassword')"
            type="password"
            v-model="password"
          >
            <template #left-icon>
              <img src="@/assets/image/pwd.png" class="left-form-icon" alt="" />
            </template>
          </van-field>
        </van-cell-group>
        <span style="text-align: left;">{{ $t('confirmpwd') }} </span>
        <van-cell-group class="login-body van-cell-group">
          <van-field
          v-if="step==0"
            clearable
            :placeholder="$t('plpassword')"
            type="password"
            v-model="password1"
          >
            <template #left-icon>
              <img src="@/assets/image/pwd.png" class="left-form-icon" alt="" />
            </template>
          </van-field>
        </van-cell-group>
        <span style="text-align: left;">{{ $t('incode') }} </span>
        <van-cell-group class="login-body van-cell-group">
          <van-field
          v-if="step==0"
            clearable
            :placeholder="$t('plcode')"
            type="text"
            v-model="inviter_id"
          >
            <template #left-icon>
              <img src="@/assets/image/user.png" class="left-form-icon" alt="" />
            </template>
          </van-field>
        </van-cell-group>
        <div class="register-bottom">
          <div>
            <van-button
              v-if="step==0"
              type="primary"
              round
              block
              class="Btnlogin"
              @click="ChangeRegister"
              >{{ $t("wanchengzhuce") }}</van-button
            >
            <van-button
              v-if="step==2"
              type="primary"
              round
              block
              class="Btnlogin"
              @click="setStep"
              >{{ $t("下一步") }}</van-button
            >
            <!-- <div class="has-acoount">
              <span>{{ $t("yiyouzhanghao") }}?</span>
              <span class="btn-tologin" @click="changeLogin">{{
                $t("qingdenglu")
              }}</span>
            </div> -->
          </div>

          <p>{{ $t("dangqianbanben") }}：21.04.02</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import loginApi from "@/api/login";
import storeAction from "@/store/typed-actions";
export default {
  name: "Register",
  components: {},
  data() {
    return {
      step:0,
      username: "",
      password: "",
      password1: "",
      inviter_id: "",
    };
  },
  methods: {
    changeLogin() {
      this.$router.push({
        path: "/login",
      });
    },
    changeHome() {
      this.$router.push({
        path: "/",
      });
    },
    setStep(){
      if (this.step==0){
        if(!this.username || !/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]*$/.test(this.username))  {
          this.$toast(this.$t('plusername'));
          return false;
        }
        this.step ++;
      }
    },
    ChangeRegister() {
      if (!this.username) {
        this.$toast(this.$t('plusername'));
      } else if (!this.password) {
        this.$toast(this.$t('plpassword'));
      } else {
        
        loginApi
          .register(this.username, this.password, "1", this.inviter_id)
          .then((data) => {
            console.log(data.data, "注册成功");
            storeAction.loginDone(data.data);
          })
          .catch((err) => {
            this.$toast.clear();
            this.$toast.fail(err.msg);
          });
      }
    },
  },
  mounted() {
    // http://localhost:8080/register?inviter_id=37
    console.log(this.$route.query.inviter_id);
    this.inviter_id = this.$route.query.inviter_id;
  },
};
</script>
<style lang="less" scoped>
.app-logo {
  width: 3.7rem;
  border-radius: 0.1rem;
  -o-object-fit: cover;
  object-fit: cover;
  position: relative;
  text-align: center;
  margin-top: 1rem;
}
.register {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  min-height: 100%;
  background-color: white;
}
.g-header {
  position: absolute;
  width: 100%;
  height: 1.18rem;
  text-align: center;
  z-index: 1;
  color: #333;
  background: rgb(14, 21, 48);
}
.left {
  z-index: 11;
  position: absolute;
  height: 100%;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.close-btn {
  padding: 0.3rem;
}
.icon-x {
  display: inline-block;
  width: 0.5rem;
  height: 0.07rem;
  background: #fff;
  line-height: 0;
  font-size: 0;
  vertical-align: middle;
  transform: rotate(45deg);
}
.icon-x::after {
  content: "";
  display: block;
  width: 0.5rem;
  height: 0.07rem;
  background: #fff;
  transform: rotate(-90deg);
}
.middle {
  z-index: 10;
  max-width: 80%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.fs-18 {
  font-size: 0.43rem;
}
.title {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.register-main {
  flex: 1;
  padding: 2rem 0.8rem 0.2rem;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  color: #766b6b;
  text-align: center;
}
.login-body {
  margin-top: 10px;
}
.van-cell-group {
  background: #fff;
  position: unset;
  input {
  }
}
.van-cell {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 0px !important;
  // padding-bottom: 20px !important;
  overflow: hidden;
  color: unset;
  font-size: 14px;
  line-height: 24px;
  background-color: unset;
  border-bottom: 1px solid #5655552e;
  margin-bottom: 10px;
}
.van-field::after {
  position: absolute;
  box-sizing: border-box;
  content: " ";
  pointer-events: none;
  right: 16px;
  bottom: 0;
  left: 16px;
  border-bottom: 0px solid #3d5179 !important;
  -webkit-transform: scaleY(0.5);
  transform: scaleY(0.5);
}
.left-form-icon {
  width: 20px;
}
.c_a0a0a0 {
  color: #766b6b;
}
.register-bottom {
  margin-top: 1rem;
  flex: 1;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.Btnlogin {
  color: #fff;
  background-color: #7d58c6;
  border: 1px solid #7d58c6;
}
.has-acoount {
  margin-top: 0.2rem;
}
.btn-tologin {
  margin-left: 0.3rem;
  color: #4bb3e8;
}
</style>
<style scoped>
/deep/.van-field__control{
  color:#333 !important;
}
</style>
